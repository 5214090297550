














































import { Component, Vue } from 'vue-property-decorator';
import * as d3 from 'd3';
import { DSVRowArray } from 'd3';
import ComorbidityPrevalance from '@/models/comorbidityPrevalance';
import DemographicComorbidities from '@/components/demographic-comorbidities.vue';
import HcruGraph from '@/components/hcru-graph.vue';
import HcruGraphOverTime from '@/components/hcru-graph-over-time.vue';
import ComorbiditiesCountOverTime from '@/components/combormidities-count-over-time.vue';

@Component({
    components: {
        DemographicComorbidities,
        HcruGraph,
        ComorbiditiesCountOverTime,
        HcruGraphOverTime,
    },
})
export default class App extends Vue {
    prevalances: Array<ComorbidityPrevalance> = [];

    prevalancesMap: { [year: number]: { [demographic: string]: { [comorbidity: string]: number } }} = {};

    hcruMap: { [type: string] : { [year: string ]: { [stratification: string]: { [ demographic: string ]: { [careType: string]: { [key: string]: number }}}}}} = {};

    prevalencesloadedCount = 0;

    totalPrevalencesToLoad = 0;

    hcruloadedCount = 0;

    totalHcruToLoad = 0;

    selectedYear = 0;

    selectedStratification = 'Gender';

    selectedHcruYear = '0';

    hcruType = 'years';

    mounted (): void {
        this.getPrevalencesCsv('/age.csv', 'Age group');
        this.getPrevalencesCsv('/gender.csv', 'Gender');
        this.getPrevalencesCsv('/deprivation.csv', 'IMD Group');
        this.getPrevalencesCsv('/ethnicity.csv', 'Ethnicity');
        this.getPrevalencesCsv('/hypertension.csv', 'Hypertension');
        this.getPrevalencesCsv('/cvd.csv', 'CVD');
        this.getPrevalencesCsv('/renal.csv', 'Renal Disease');
        this.getPrevalencesCsv('/bmi.csv', 'BMI');
        this.getPrevalencesCsv('/multimorbidityGroup.csv', 'Multimorbidity Group');
        this.getPrevalencesCsv('/populationAverage.csv', 'T2DM Population');
        this.getHcruCsv('/hcru.csv', 'years');
        this.getHcruCsv('/hcru-diagnosis-years.csv', 'diagnosis');
    }

    yearChanged (year: number) {
        this.selectedYear = year;
    }

    hcruTypeChanged (type: string) {
        this.hcruType = type;
    }

    hcruYearChanged (year: string) {
        this.selectedHcruYear = year;
    }

    seriesToggled (data: { name: string; visible: boolean }) {
        console.log(data);
        (this.$refs.hcruTime as HcruGraphOverTime).seriesToggled(data);
    }

    stratificationChanged (stratifiction: string) {
        this.selectedStratification = stratifiction;
    }

    getHcruCsv (csvPath: string, type: string): void {
        this.totalHcruToLoad += 1;

        d3.csv(csvPath).then((result: DSVRowArray<string>) => {
            result.forEach((row) => {
                const year = row.Year as string;
                const stratifiction = row.Stratifiction as string;
                const demographic = row.Subgroup as string;
                if (['NA', 'Unknown'].indexOf(demographic) > -1) return;

                // const patients = row.Patients as string;
                Object.keys(row).forEach((cell) => {
                    if (cell !== 'Year' && cell !== 'Stratifiction'
                        && cell !== 'Patients' && cell !== 'Subgroup') {
                        const careGroup = cell.split('-')[0];
                        const key = cell.split('-')[1];
                        if (!this.hcruMap[type]) this.hcruMap[type] = {};
                        if (!this.hcruMap[type][year]) this.hcruMap[type][year] = {};
                        if (!this.hcruMap[type][year][stratifiction]) this.hcruMap[type][year][stratifiction] = {};
                        if (!this.hcruMap[type][year][stratifiction][demographic]) this.hcruMap[type][year][stratifiction][demographic] = {};
                        if (!this.hcruMap[type][year][stratifiction][demographic][careGroup]) this.hcruMap[type][year][stratifiction][demographic][careGroup] = {};
                        this.hcruMap[type][year][stratifiction][demographic][careGroup][key] = Number(row[cell]);
                    }
                });
            });
            this.hcruloadedCount += 1;
        });
    }

    getPrevalencesCsv (csvPath: string, stratification: string): void {
        this.totalPrevalencesToLoad += 1;
        d3.csv(csvPath).then((result: DSVRowArray<string>) => {
            result.forEach((row) => {
                Object.keys(row).forEach((cell) => {
                    if (cell === 'comorbidity') return;
                    const year = Number(cell.substring(cell.indexOf('Year') + 4));
                    const demographic = cell.substring(0, cell.indexOf('Year'));
                    if (['NADeprived', 'BMINA', 'EthnicityNotStated'].indexOf(demographic) > -1) return;

                    const value = Number(row[cell]);
                    const comorbidity = row.comorbidity?.trim() || '';
                    const comorbidityPrevalance = new ComorbidityPrevalance();
                    comorbidityPrevalance.year = year;
                    comorbidityPrevalance.demographic = demographic;
                    comorbidityPrevalance.value = value;
                    comorbidityPrevalance.comorbidity = row.comorbidity?.trim() || '';
                    comorbidityPrevalance.stratification = stratification;

                    this.prevalances.push(comorbidityPrevalance);
                    if (!this.prevalancesMap[year]) this.prevalancesMap[year] = {};
                    if (!this.prevalancesMap[year][demographic]) this.prevalancesMap[year][demographic] = {};
                    this.prevalancesMap[year][demographic][comorbidity] = value;
                });
            });
            this.prevalencesloadedCount += 1;
        });
    }
}
