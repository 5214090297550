























































import ComorbidityPrevalance from '@/models/comorbidityPrevalance';
import { Component, Prop, Vue } from 'vue-property-decorator';
import PercentagePie from './percentage-pie.vue';
import CompareLine from './compare-line.vue';
import DemographicComorbiditiesRow from './demographic-comorbidities-row.vue';
import DemographicComorbiditiesLine from './demographic-comorbidities-line.vue';

@Component({
    components: {
        PercentagePie,
        CompareLine,
        DemographicComorbiditiesLine,
        DemographicComorbiditiesRow,
    },
})
export default class DemographicComorbidities extends Vue {
    @Prop() private prevalances!: Array<ComorbidityPrevalance>;

    @Prop() private prevalancesMap!: { [year: number]: { [demographic: string]: { [comorbidity: string]: number } }};

    @Prop() private selectedYear!: number;

    @Prop() private selectedStratification!: string;

    isMounted = false;

    sortedBy = 'Comorbidity';

    sortReverse = false;

    selectedComorbidity = '';

    mounted () {
        this.$nextTick(() => {
            this.isMounted = true;
        });
    }

    selectedStratificationChanged (e: InputEvent) {
        this.$emit('stratificationChanged', (e.target as HTMLSelectElement).value);
    }

    selectedComorbidityChanged (comorbidity: string) {
        this.selectedComorbidity = comorbidity;
    }

    get selectedDemographics () {
        return [...this.stratifications.find((a) => a.stratification === this.selectedStratification)!.demographics, 'T2DMPopulation'].filter((a) => this.naDemographics.indexOf(a) === -1);
    }

    get naDemographics () {
        return [
            'NADeprived',
            'BMINA',
            'EthnicityNotStated',
        ];
    }

    get stratifications () {
        const stratifications: { [stratification: string]: Array<string> } = {};
        this.prevalances.forEach((p) => {
            if (!stratifications[p.stratification]) stratifications[p.stratification] = [];
            if (stratifications[p.stratification].indexOf(p.demographic) === -1) {
                stratifications[p.stratification].push(p.demographic);
            }
        });
        return Object.keys(stratifications).filter((a) => a !== 'T2DM Population').map((a) => ({ stratification: a, demographics: stratifications[a] }));
    }

    get comorbidityNames (): Array<string> {
        return [...new Set(this.prevalances.map((a) => a.comorbidity).filter((a) => a !== 'Total'))];
    }

    get sortedComorbidities () {
        let namesToReturn = [...this.comorbidityNames];
        if (this.sortedBy !== 'Comorbidity') {
            this.demographics.forEach((selectedDemographic) => {
                if (selectedDemographic === this.sortedBy) {
                    namesToReturn = namesToReturn
                        .sort((a, b) => (this.getPrevalance(a, selectedDemographic) > this.getPrevalance(b, selectedDemographic) ? -1 : 1));
                }
            });
        }
        if (this.sortReverse) {
            namesToReturn.reverse();
        }
        return namesToReturn;
    }

    sortBy (sortValue: string) {
        this.sortReverse = !this.sortReverse && sortValue === this.sortedBy;
        this.sortedBy = sortValue;
    }

    getPrevalance (comorbidity: string, demographic: string): number {
        return this.prevalancesMap[this.selectedYear]?.[demographic]?.[comorbidity];
    }

    get demographics (): Array<string> {
        return [...new Set(this.prevalances.map((a) => a.demographic))];
    }

    get tableWidth () {
        return this.isMounted && (this.$refs.table as HTMLElement).offsetWidth;
    }

    formatDemographic (demographic: string) {
        switch (demographic) {
        case 'NADeprived':
            return 'N/A';
        case 'BMINA':
            return 'N/A';
        case 'BMI<=30':
            return 'BMI <= 30';
        case 'BMI>30':
            return 'BMI > 30';
        case 'T2DMPopulation':
            return 'T2DM Population';
        default:
            return demographic.replace(/([A-Z]+)/g, ' $1').replace(/^,/, '');
        }
    }
}
