import { render, staticRenderFns } from "./combormidities-count-over-time.vue?vue&type=template&id=53694ae0&scoped=true&"
import script from "./combormidities-count-over-time.vue?vue&type=script&lang=ts&"
export * from "./combormidities-count-over-time.vue?vue&type=script&lang=ts&"
import style0 from "./combormidities-count-over-time.vue?vue&type=style&index=0&id=53694ae0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53694ae0",
  null
  
)

export default component.exports