import Vue from 'vue';
import HighchartsVue from 'highcharts-vue';
import Highcharts, { Chart } from 'highcharts';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    faHome, faMapMarkedAlt, faExpandAlt, faChartScatter, faTriangle, faInfo, faBars,
    faHistory, faAsterisk, faFileAlt, faQuestionCircle, faInfoCircle, faEnvelope, faTimes, faSearch,
    faPlus, faMinus, faCaretDown, faChevronRight, faChevronLeft, faNewspaper, faVirus, faPercent, faUsers, faMoneyBillWave, faBadgePercent, faAngleDoubleLeft, faAngleDoubleRight, faUserFriends, faChild, faTombstone, faTombstoneAlt, faCalendarTimes, faUserClock, faCalendarAlt, faCalendarWeek, faCalendarCheck, faStopwatch, faLocation, faTable,
} from '@fortawesome/pro-duotone-svg-icons';

import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import router from './router';
import App from './App.vue';

Vue.config.productionTip = false;
Vue.use(HighchartsVue, { Highcharts });

library.add(faHome, faMapMarkedAlt, faExpandAlt, faChartScatter, faTriangle, faInfo, faBars, faAsterisk, faCaretDown, faUserFriends,
    faHistory, faFileAlt, faQuestionCircle, faInfoCircle, faEnvelope, faTimes, faTwitter, faLinkedin, faPlus, faMinus, faAngleDoubleLeft, faAngleDoubleRight,
    faCalendarTimes, faUserClock, faCalendarAlt, faCalendarWeek, faCalendarCheck, faStopwatch, faLocation, faSearch, faMapMarkedAlt, faTable,
    faChevronRight, faChevronLeft, faNewspaper, faChartScatter, faVirus, faPercent, faUsers, faMoneyBillWave, faBadgePercent, faChild, faTombstone, faTombstoneAlt);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Highcharts.Pointer.prototype.reset = function disableMouseOut () {
    const newLocal: { chart: Chart } = this as unknown as { chart: Chart };
    newLocal.chart.tooltip.hide();
    return undefined;
};
new Vue({
    render: (h) => h(App),
    router,
}).$mount('#app');
