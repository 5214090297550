







import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

@Component
export default class DemographicComorbiditiesValue extends Vue {
    @Prop() private value!: number;

    isMounted = false;

    mounted () {
        this.isMounted = true;
        this.animate(0);
    }

    @Watch('value', { immediate: true })
    valueChanged (newValue: number, previousValue: number) {
        this.animate(previousValue);
    }

    animate (previousValue: number) {
        if (previousValue === this.value || !this.isMounted || this.value === undefined) return;
        this.$nextTick(() => {
            this.animateValue(this.$refs.value as HTMLElement, (previousValue || 0) * 100, this.value * 100, 500);
        });
    }

    formatPercentage (value: number): string {
        return value !== undefined ? `${Math.round(value * 10000) / 100}` : '';
    }

    animateValue (el: HTMLElement, start: number, end: number, duration: number) {
        let startTimestamp: number | null = null;
        if (!el) return;
        const step = (timestamp: number) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            el.innerHTML = `${(Math.floor((progress * (end - start) + start) * 10) / 10).toString()}`;
            if (progress < 1) {
                window.requestAnimationFrame(step);
            } else {
                el.innerHTML = this.formatPercentage(this.value);
            }
        };
        window.requestAnimationFrame(step);
    }
}
