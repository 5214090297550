<template>
 <div id="app">

    <nav role="navigation">
        <font-awesome-icon :icon="['fad', 'bars']" class="hamburger" @click="showMenu = !showMenu"/>
        <ul v-show="showMenu" @click="showMenu = false">
            <div class="top">
                <li>
                    <router-link to="/" aria-label="Home" title="Home">
                        <font-awesome-icon :icon="['fad', 'home']" />
                    </router-link>
                </li>
                <!-- <li>
                    <router-link to="/analysis" aria-label="Deprivation analysis" title="Deprivation analysis">
                        <font-awesome-icon :icon="['fad', 'chart-scatter']" />
                    </router-link>
                </li> -->
                <li>
                    <router-link to="/dataAndMethod" aria-label="Data and Method" title="Data and method">
                        <font-awesome-icon :icon="['fad', 'info-circle']" />
                    </router-link>
                </li>
                <li>
                    <router-link to="/insight" aria-label="Insight and Commentary" title="Insight and Commentary">
                        <font-awesome-icon :icon="['fad', 'newspaper']" />
                    </router-link>
                </li>
                <!-- <li>
                    <router-link to="/faq" aria-label="FAQ" title="FAQ">
                        <font-awesome-icon :icon="['fad', 'question-circle']" />
                    </router-link>
                </li> -->
            </div>
            <div class="bottom">
                <li>
                    <a aria-label="Stay up to date" title="Stay up to date" @click="showContact = true" href="javascript:void(0)">
                        <font-awesome-icon :icon="['fad', 'envelope']" />
                    </a>
                </li>
                <li>
                    <a aria-label="Twitter" title="Twitter" href="https://twitter.com/lcp_actuaries" target="_blank">
                        <font-awesome-icon :icon="['fab', 'twitter']" />
                    </a>
                </li>
                <li>
                    <a aria-label="LinkedIn" title="LinkedIn" href="https://www.linkedin.com/company/lcp-health-analytics/" target="_blank">
                        <font-awesome-icon :icon="['fab', 'linkedin']" />
                    </a>
                </li>
                <li>
                    <router-link to="/terms" aria-label="Terms" title="Terms">
                        <font-awesome-icon :icon="['fad', 'file-alt']" />
                    </router-link>
                </li>
            </div>
        </ul>
    </nav>
    <keep-alive>
        <router-view  role="main" @contact="showContact = true"/>
    </keep-alive>
    <div class="contact-wrapper" @click.self="showContact = false" v-if="showContact">
        <div class="contact-form">
            <button title="Close contact form" aria-label="Close contact form" @click="showContact = false">
                <font-awesome-icon :icon="['fad', 'times' ]" />
            </button>
            <iframe src="https://insight.lcp.uk.com/acton/form/20628/0149:d-0001/0/-/-/-/-/index.htm" />
        </div>
    </div>
  </div>
</template>
<script>
export default {
    data () {
        return {
            showMenu: false,
            showContact: false,
        };
    },
};
</script>
<style lang="scss">
@import '@/scss/reset.scss';
@import '@/scss/colours.scss';

.cookiefirst-root [data-cookiefirst-action="open-preferences"] {
    bottom: 15px !important;
    left: auto !important;
    right: 15px !important;
    background: none !important;
}

html, body {
  height: 100%;
}

.cookies {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background: #eee;
    z-index: 99;
    padding: 1rem;
    border-top: 1px solid #eee;
    box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 10px;
    text-align: center;
    p {
        text-align: left;
    }
    button {
        background: $lcpBlue;
        color: #fff;
        padding: 0.5rem 1rem;
        margin-top: 1rem;
        border-radius: 0.25rem;
    }
}

text {
    font-family: 'Work Sans', sans-serif;
}

.x-axis, .y-axis {
    text {
        font-size: 12px;
    }
}

text.yAxisTitle {
    font-size: 18px;
}

.bottom {
    position: absolute;
    bottom: 0;
}

#app {
    font-family: 'Work Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100%;
    background: #fafafa;
    overflow: hidden;
    display: flex;
    @media(max-width: 1024px) {
        flex-direction: column;
        height: 100%;
    }
}

.hamburger {
    display: none;
}

@keyframes rotate {
    from { transform: rotate(0)  }
    to { transform: rotate(360deg) }
}

.loading {
    height: 100px;
    width: 100px;
    border-radius: 100%;
    position: absolute;
    border-left: 1px solid $lcpGrapefruit;
    border-bottom: 2px solid $lcpGrapefruit;
    border-right: 3px solid $lcpGrapefruit;
    border-top: 0px solid $lcpGrapefruit;
    animation: rotate 1s infinite;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
}

button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    font-family: 'Work Sans', sans-serif;
}

.contact-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    z-index: 100;
    .contact-form {
        button {
            font-size: 2rem;
            position: absolute;
            top: 0.5rem;
            right: 2rem;
        }
        position: absolute;
        overflow: hidden;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        height: 90vh;
        border-radius: 0.5rem;
        width: 800px;
        max-width: 100vw;
        padding: 0.5rem;
        text-align: center;
        iframe {
            height: 100%;
            width: 100%;
            max-width: 100vw;
        }
    }
}

nav {
    background: #fff;
    height: 100%;
    border-right: 1px solid #eee;
    @media(max-width: 900px) {
        background: #f4f4f4;
        z-index: 99;
        height: 40px;
        .bottom {
            position: relative;
        }
        .hamburger {
            display: block;
            margin: 0.5rem;
            font-size: 2rem;
        }
        ul {
            position: fixed;
            top: 40px;
            background: #f4f4f4;
            z-index: 2;
        }
    }
    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        @media (min-width: 901px) {
            display: block !important;
        }
    }

    li {
        font-size: 2rem;
        color: #666;
        padding: 1rem;
        text-align: center;
        &:hover {
            background: #fff;
        }
    }

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: $lcpBlue;
        }
    }
}
</style>
