



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PercentagePie extends Vue {
    @Prop() private percentage!: number;

    @Prop() private t2dmValue!: number;

    get strokeDashArray () {
        return `${this.percentage * 100}, 100`;
    }

    get strokeColour () {
        if (this.percentage > this.t2dmValue && this.percentage - this.t2dmValue > 0.1) return '#f27174';
        if (this.percentage > this.t2dmValue) return '#faa743';
        if (this.percentage < this.t2dmValue) return '#00a3c7';
        return '#8da8ad';
    }
}
